import { bsc, mainnet } from 'viem/chains'

import type { ConfiguredChainId } from './config'

/**
 * These contracts are hardcoded, because they are referenced in components
 */

interface Contract {
  chainId: ConfiguredChainId
  address: `0x${string}`
}

export const tensetContract: Contract = {
  chainId: bsc.id,
  address: '0x1AE369A6AB222aFF166325B7b87Eb9aF06C86E57',
}

export const tensetXContract: Contract = {
  chainId: bsc.id,
  address: '0xA6054bb258059b59E9Dbb31D19eaf19Ba6519e44',
}

export const vaultContract: Contract = {
  chainId: bsc.id,
  address: '0x0e3234056ad5b469ada1afd30ee3719ac99a361f',
}

export const nftVaultContract: Contract = {
  chainId: mainnet.id,
  address: '0xb014bb6c2a01cc367948acea5ba64053b8057b41',
}
